var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"rqltltx"},[_c('div',{staticClass:"bgmain"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"脱硫脱硝系统")]),_c('div',{staticClass:"row1-main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"leftrow1"},[_vm._v("炉膛口")]),_vm._m(0),_c('div',{staticClass:"leftrow3"},[_vm._v("压缩空气")]),_c('div',{staticClass:"leftrow4"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx fl",style:({
                    background:
                      _vm.infoList.MCQK__p__QK20 && !_vm.infoList.MCQK__p__QK20.TS
                        ? '#2AFC30'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.MCQK__p__QK20
                      ? _vm.toDetail(
                          2,
                          'QK20',
                          'MCQK__p__QK20',
                          '',
                          '燃料量-脱硝前馈'
                        )
                      : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx fl",style:({
                    background:
                      _vm.infoList.MCXK__p__XK23 && !_vm.infoList.MCXK__p__XK23.RM
                        ? '#2AFC30'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.MCXK__p__XK23
                      ? _vm.toDetail(1, 'XK23', 'MCXK__p__XK23', '', 'NOx控制')
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"0.01vw"},style:({
                    background:
                      _vm.infoList.MCMAN__p__MAN30 &&
                      _vm.infoList.MCMAN__p__MAN30.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.MCMAN__p__MAN30
                      ? _vm.toCompon(
                          0,
                          'RM',
                          'MCMAN__p__MAN30',
                          'MAN30_AV_NG3',
                          '尿素溶液手操器',
                          'KF_TX_B',
                          'MCSOUREC',
                          'KF_TX_NG3'
                        )
                      : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"down leftrow4jg",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.MCSO2NOX.NOXSP,
                      'NOx设定点',
                      'NOXSP',
                      'MCSO2NOX'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSO2NOX.NOXSP)+" ")]),_c('div',{staticClass:"leftrow4jg1",on:{"click":function($event){return _vm.toCompon(2, 'NOXSPSJ', 'MCSO2NOX', 'NOXSPSJ_NG3','氮氧化物实际控制点')},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.MCSO2NOX.NOXSPSJ,
                      'NOXSPSJ',
                      'MCSO2NOX',
                      'NOXSPSJ_NG3'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSO2NOX.NOXSPSJ)+" ")])]),_c('div',{staticClass:"fl leftrow4jg2"},[_c('div',{staticClass:"bottonx fl",style:({
                    background:
                      _vm.infoList.MCXK__p__XK27 && !_vm.infoList.MCXK__p__XK27.RM
                        ? '#2AFC30'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.MCXK__p__XK27
                      ? _vm.toDetail(
                          1,
                          'XK27',
                          'MCXK__p__XK27',
                          '',
                          '化水除盐水流量控制'
                        )
                      : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"0.01vw"},style:({
                    background:
                      _vm.infoList.MCMAN__p__MAN32 &&
                      _vm.infoList.MCMAN__p__MAN32.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }),on:{"click":function($event){_vm.infoList.MCMAN__p__MAN32
                      ? _vm.toCompon(
                          0,
                          'RM',
                          'MCMAN__p__MAN32',
                          'MAN32_AV_NG3',
                          '脱硝除盐水手操器',
                          'KF_CYS_B',
                          'MCSOUREC',
                          'KF_CYS_NG3'
                        )
                      : ''}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"leftrow5"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'F_NSRY_B', 'MCSOUREC', 'F_NSRY_NG3','尿素溶液流量')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.F_NSRY_B,
                        'F_NSRY_B',
                        'MCSOUREC',
                        'F_NSRY_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.F_NSRY_B)+" t/h ")])]),_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'KF_TX_B', 'MCSOUREC', 'KF_TX_NG3','炉内SNCR脱硝脱硝剂控制阀门反馈')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.KF_TX_B,
                        'KF_TX_B',
                        'MCSOUREC',
                        'KF_TX_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.KF_TX_B)+" % ")])])]),_c('div',{staticClass:"fl leftrow5jg"},[_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'F_CYS_B', 'MCSOUREC', 'F_CYS_NG3','化水除盐水流量')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.F_CYS_B,
                        'F_CYS_B',
                        'MCSOUREC',
                        'F_CYS_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.F_CYS_B)+" t/h ")])]),_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'KF_CYS_B', 'MCSOUREC', 'KF_CYS_NG3','化水除盐水阀门反馈')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.KF_CYS_B,
                        'KF_CYS_B',
                        'MCSOUREC',
                        'KF_CYS_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.KF_CYS_B)+" % ")])])])]),_vm._m(1)]),_c('div',{staticClass:"centermain fl"},[_c('div',{staticClass:"cen-top"},[_c('div',{staticClass:"fl centerrow1"},[_c('div',{staticClass:"cen-co1"},[_vm._v("原烟气监测")]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("SO₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ASO2_Y_B', 'MCSOUREC', 'ASO2_Y_NG3','锅炉出口SO2含量')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ASO2_Y_B,
                          'ASO2_Y_B',
                          'MCSOUREC',
                          'ASO2_Y_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ASO2_Y_B)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("NOx浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ANOX_Y_B', 'MCSOUREC', 'ANOX_Y_NG3','原烟气NOX浓度')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ANOX_Y_B,
                          'ANOX_Y_B',
                          'MCSOUREC',
                          'ANOX_Y_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ANOX_Y_B)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("O₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ATO2_Y_B', 'MCSOUREC', 'ATO2_Y_NG3','原烟气含氧量')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ATO2_Y_B,
                          'ATO2_Y_B',
                          'MCSOUREC',
                          'ATO2_Y_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ATO2_Y_B)+" % ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("DUST浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ATDUST_Y_B', 'MCSOUREC', 'ATDUST_Y_NG3','原烟气含尘量')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ATDUST_Y_B,
                          'ATDUST_Y_B',
                          'MCSOUREC',
                          'ATDUST_Y_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ATDUST_Y_B)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("烟气温度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'TYQ_Y_B', 'MCSOUREC', 'TYQ_Y_NG3','原烟气烟气温度')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.TYQ_Y_B,
                          'TYQ_Y_B',
                          'MCSOUREC',
                          'TYQ_Y_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.TYQ_Y_B)+" ℃ ")])]),_c('div',{staticClass:"leftrow5wd"})])]),_c('div',{staticClass:"fl centerrow1"},[_c('div',{staticClass:"cen-co1"},[_vm._v("净烟气监测")]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("SO₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ASO2_B', 'MCSOUREC', 'ASO2_NG3','SO2浓度')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ASO2_B,
                          'ASO2_B',
                          'MCSOUREC',
                          'ASO2_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ASO2_B)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("NOx浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ANOX_B', 'MCSOUREC', 'ANOX_NG3','nox浓度')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ANOX_B,
                          'ANOX_B',
                          'MCSOUREC',
                          'ANOX_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ANOX_B)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("O₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ATO2_B', 'MCSOUREC', 'ATO2_NG3','净烟气烟气含氧量')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ATO2_B,
                          'ATO2_B',
                          'MCSOUREC',
                          'ATO2_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ATO2_B)+" % ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("DUST浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'ATDUST_B', 'MCSOUREC', 'ATDUST_NG3','净烟气烟尘浓度')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.ATDUST_B,
                          'ATDUST_B',
                          'MCSOUREC',
                          'ATDUST_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.ATDUST_B)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("烟气温度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'T_YQ_B', 'MCSOUREC', 'T_YQ_NG3','净烟气烟气温度')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.T_YQ_B,
                          'T_YQ_B',
                          'MCSOUREC',
                          'T_YQ_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.T_YQ_B)+" ℃ ")])]),_c('div',{staticClass:"leftrow5wd"})])])]),_c('div',{staticClass:"cen-lable"},[_vm._v("工艺水")]),_c('div',{staticClass:"cen-lable1"},[_vm._v("原烟气")]),_c('div',{staticClass:"cen-lable2"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'TYQ_Y_B', 'MCSOUREC', 'TYQ_Y_NG3','原烟气烟气温度')},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.MCSOUREC.TYQ_Y_B,
                    'TYQ_Y_B',
                    'MCSOUREC',
                    'TYQ_Y_NG3'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.TYQ_Y_B)+" ℃ ")])]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵A")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵B")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵C")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵D")])]),_c('div',{staticClass:"rightmain fl"},[_c('div',{staticClass:"rig-left fl"},[_c('div',{staticClass:"rig-lable1"},[_vm._v("脱硫塔")]),_c('div',{staticClass:"rig-lable1 rig-jge"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'T_XST1_B', 'MCSOUREC', 'T_XST1_NG3','吸收塔内温度')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.T_XST1_B,
                        'T_XST1_B',
                        'MCSOUREC',
                        'T_XST1_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.T_XST1_B)+" ℃ ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'T_XST2_B', 'MCSOUREC', 'T_XST2_NG3','吸收塔内温度')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.T_XST2_B,
                        'T_XST2_B',
                        'MCSOUREC',
                        'T_XST2_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.T_XST2_B)+" ℃ ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge1"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'LTJYC1_B', 'MCSOUREC', 'LTJYC1_NG3','吸收塔浆液池液位')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.LTJYC1_B,
                        'LTJYC1_B',
                        'MCSOUREC',
                        'LTJYC1_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.LTJYC1_B)+" m ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'LTJYC2_B', 'MCSOUREC', 'LTJYC2_NG3','吸收塔浆液池液位')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.LTJYC2_B,
                        'LTJYC2_B',
                        'MCSOUREC',
                        'LTJYC2_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.LTJYC2_B)+" m ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2",staticStyle:{"margin-left":"0.5vw"}},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'DTJYC1_B', 'MCSOUREC', 'DTJYC1_NG3','吸收塔浆液池密度')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.DTJYC1_B,
                        'DTJYC1_B',
                        'MCSOUREC',
                        'DTJYC1_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.DTJYC1_B)+" g/m3 ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'DTJYC2_B', 'MCSOUREC', 'DTJYC2_NG3','吸收塔浆液池密度')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.DTJYC2_B,
                        'DTJYC2_B',
                        'MCSOUREC',
                        'DTJYC2_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.DTJYC2_B)+" g/m3 ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'PH1_B', 'MCSOUREC', 'PH1_NG3','吸收塔浆液池PH值')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.PH1_B,
                        'PH1_B',
                        'MCSOUREC',
                        'PH1_NG3'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.PH1_B)+" ph ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'PH2_B', 'MCSOUREC', 'PH2_B')},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.PH2_B,
                        'PH2_B',
                        'MCSOUREC',
                        'PH2_B'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.PH2_B)+" ph ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{staticClass:"down",on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList.MCSO2NOX.PH_H,
                        'PH值上限设定',
                        'PH_H',
                        'MCSO2NOX'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSO2NOX.PH_H)+" ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{staticClass:"down",on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList.MCSO2NOX.PH_L,
                        'PH值下限设定',
                        'PH_L',
                        'MCSO2NOX'
                      )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSO2NOX.PH_L)+" ")])])])]),_c('div',{staticClass:"rig-right fl"},[_c('div',{staticClass:"rig-lable1"},[_c('div',{staticClass:"rig-rightjg",on:{"click":function($event){return _vm.toCompon(2, 'T_YQ_B', 'MCSOUREC', 'T_YQ_NG3','净烟气烟气温度')},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.MCSOUREC.T_YQ_B,
                      'T_YQ_B',
                      'MCSOUREC',
                      'T_YQ_NG3'
                    )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.T_YQ_B)+" ℃ ")])]),_c('div',{staticClass:"righ-lable2"},[_vm._v("除雾器冲洗水")]),_c('div',{staticClass:"righ-lable3"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"righ-col1"},[_c('div',{staticClass:"righ-col1-lb fl down",on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.MCSO2NOX.SO2SP,
                          'SO2设定点',
                          'SO2SP',
                          'MCSO2NOX'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSO2NOX.SO2SP)+" ")]),_c('div',{staticClass:"righ-col1-lb fl",on:{"click":function($event){return _vm.toCompon(2, 'SO2SPSJ', 'MCSO2NOX', 'SO2SPSJ_NG3','二氧化硫实际控制点')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSO2NOX.SO2SPSJ,
                          'SO2SPSJ',
                          'MCSO2NOX',
                          'SO2SPSJ_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSO2NOX.SO2SPSJ)+" ")])]),_c('div',{staticClass:"righ-col1 righ-col1-jg"},[_c('div',{staticClass:"righ-col1-lb fl",on:{"click":function($event){return _vm.toCompon(2, 'F_SHJY_B', 'MCSOUREC', 'F_SHJY_NG3','石灰浆液流量')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.F_SHJY_B,
                          'F_SHJY_B',
                          'MCSOUREC',
                          'F_SHJY_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.F_SHJY_B)+" m3/h ")]),_c('div',{staticClass:"righ-col1-lb righ-col1-jg2 fl",on:{"click":function($event){return _vm.toCompon(2, 'KF_TL_B', 'MCSOUREC', 'KF_TL_NG3','脱硫石灰浆液流量控制阀门反馈')},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.MCSOUREC.KF_TL_B,
                          'KF_TL_B',
                          'MCSOUREC',
                          'KF_TL_NG3'
                        )}}},[_vm._v(" "+_vm._s(_vm.infoList.MCSOUREC.KF_TL_B)+" % ")])]),_c('div',{staticClass:"righ-col1 righ-col1-jg3"},[_c('div',{staticClass:"bottonx fl jgxx",style:({
                        background:
                          _vm.infoList.MCQK__p__QK21 && !_vm.infoList.MCQK__p__QK21.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.MCQK__p__QK21
                          ? _vm.toDetail(
                              2,
                              'QK21',
                              'MCQK__p__QK21',
                              '',
                              '燃料量-脱硫前馈'
                            )
                          : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx fl",style:({
                        background:
                          _vm.infoList.MCQK__p__QK22 && !_vm.infoList.MCQK__p__QK22.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.MCQK__p__QK22
                          ? _vm.toDetail(
                              2,
                              'QK22',
                              'MCQK__p__QK22',
                              '',
                              '出口SO2-脱硫前馈'
                            )
                          : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx fl",style:({
                        background:
                          _vm.infoList.MCXK__p__XK26 && !_vm.infoList.MCXK__p__XK26.RM
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.MCXK__p__XK26
                          ? _vm.toDetail(
                              1,
                              'XK26',
                              'MCXK__p__XK26',
                              '',
                              '吸收塔浆液池PH范围控制'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",style:({
                        background:
                          _vm.infoList.MCXK__p__XK25 && !_vm.infoList.MCXK__p__XK25.RM
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.MCXK__p__XK25
                          ? _vm.toDetail(1, 'XK25', 'MCXK__p__XK25', '', 'SO2控制')
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",style:({
                        background:
                          _vm.infoList.MCXK__p__XK24 && !_vm.infoList.MCXK__p__XK24.RM
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.MCXK__p__XK24
                          ? _vm.toDetail(
                              1,
                              'XK24',
                              'MCXK__p__XK24',
                              '',
                              '石灰浆液流量控制'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",style:({
                        background:
                          _vm.infoList.MCMAN__p__MAN31 &&
                          _vm.infoList.MCMAN__p__MAN31.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.MCMAN__p__MAN31
                          ? _vm.toCompon(
                              0,
                              'RM',
                              'MCMAN__p__MAN31',
                              'MAN31_AV_NG3',
                              '脱硫石灰浆液手操器',
                              'KF_TL_B',
                              'MCSOUREC',
                              'KF_TL_NG3'
                            )
                          : ''}}},[_vm._v(" A ")])])]),_vm._m(2)]),_vm._m(3)])])])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"pmark":_vm.Panualmark,"pnode":_vm.Panualnode,"pnm":_vm.Panualnodem,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.flag == 4 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('RqCommonParameters',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 6 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('RqSoftMeasurement',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 7 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('RqOnlineService',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftrow2"},[_c('div',{staticClass:"fl left-lable"},[_vm._v("1#尿素分配")]),_c('div',{staticClass:"fl left-lable leftjg"},[_vm._v("2#尿素分配")]),_c('div',{staticClass:"fl left-lable leftjg"},[_vm._v("3#尿素分配")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftrow5"},[_c('div',{staticClass:"fl leftrow5jg3"},[_vm._v("尿素溶液")]),_c('div',{staticClass:"fl leftrow5jg2"},[_vm._v("化水除盐水")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fl jange"},[_c('div',{staticClass:"mag-top1"},[_vm._v("石灰石旋流站")]),_c('div',{staticClass:"mag-top2"},[_vm._v("事故浆液罐")]),_c('div',{staticClass:"mag-top3"},[_vm._v("集水坑")]),_c('div',{staticClass:"mag-top1"},[_vm._v("石灰石浆液")]),_c('div',{staticClass:"mag-top4"},[_vm._v("氧化空气")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"righ-lable4"},[_c('div',{staticClass:"righ-col21 fl"},[_c('div',{staticClass:"jianh"},[_vm._v("石膏排除泵A")]),_c('div',{staticClass:"jianh1"},[_vm._v("石膏排除泵B")])]),_c('div',{staticClass:"righ-col21 jianh3 fl"},[_c('div',{},[_vm._v("工艺水")]),_c('div',{staticClass:"jianh4"},[_vm._v("事故浆液罐")]),_c('div',{staticClass:"jianh4"},[_vm._v("石膏旋流站")])])])}]

export { render, staticRenderFns }